var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0",attrs:{"id":"h-section-about"}},[_c('HSectionTitle',{staticClass:"mb-12",attrs:{"title":_vm.title,"subtitle":_vm.subtitle}}),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"0"}},[_c('div',{staticClass:"masked-image",style:(_vm.$vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' })},[_c('v-img',{staticClass:"image-main",attrs:{"src":require("@/assets/images/about-frog.jpg"),"lazy-src":require("@/assets/lazy/about-frog.jpg")}}),_c('v-img',{staticClass:"image-mask",attrs:{"src":require("@/assets/images/about-mask.svg")}}),_c('div',{staticClass:"dot dot-A levitate"}),_c('div',{staticClass:"dot dot-B levitate delay-C"}),_c('div',{staticClass:"dot dot-C levitate delay-B"})],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
              ? 'mx-4'
              : _vm.$vuetify.breakpoint.smOnly
              ? 'mx-12'
              : ''},[_c('HRowTitle',{attrs:{"title":_vm.firstRow.title,"subtitle":_vm.firstRow.subtitle}}),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.firstRow.paragraphs}})],1)])],1),_c('div',{staticClass:"mb-12 special-block"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : _vm.$vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''},[_c('HRowTitle',{attrs:{"title":_vm.secondRow.title,"subtitle":_vm.secondRow.subtitle}}),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.secondRow.paragraphs}})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"0"}},[_c('div',{staticClass:"d-flex align-center justify-center",style:(_vm.$vuetify.breakpoint.mdOnly
                ? { width: '90%' }
                : { width: '100%' })},[_c('div',{staticClass:"left-frog mr-8"},[_c('HLogoSymbolMark')],1),_c('div',{staticClass:"middle-frog"},[_c('HLogoSymbolMark')],1),_c('div',{staticClass:"right-frog ml-2"},[_c('HLogoSymbolMark')],1)])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : _vm.$vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''},[_c('HRowTitle',{attrs:{"title":_vm.thirdRow.title,"subtitle":_vm.thirdRow.subtitle}}),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.thirdRow.paragraphs}})],1)])],1),_c('div',{staticClass:"bubbles-img"},[_c('v-img',{attrs:{"src":require("@/assets/images/about-bubble.svg")}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }