var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""},on:{"&wheel":function($event){return _vm.setWheel()},"&keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.setWheel()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.setWheel()}]}},[_c('HHero',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.intersectHero,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: intersectHero,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}],ref:"heroRef"}),_c('HSectionProduct',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.handleIntersect,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: handleIntersect,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}],on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}}),_c('HSectionFeatures',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.handleIntersect,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: handleIntersect,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}],on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}}),_c('HSectionWhy',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.handleIntersect,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: handleIntersect,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}],on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}}),_c('HSectionTestimonials',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.intersectTestimonial,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: intersectTestimonial,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}],attrs:{"active":_vm.testimonialActive},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}}),_c('HSectionAbout',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.handleIntersect,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: handleIntersect,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}]}),_c('HSectionContact',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:({
      handler: _vm.handleIntersect,
      options: { threshold: [0.25, 0.75] }
    }),expression:"{\n      handler: handleIntersect,\n      options: { threshold: [0.25, 0.75] }\n    }",modifiers:{"quiet":true}}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }