<template>
  <div class="pa-0 ma-0" id="h-section-contact">
    <HSectionTitle :title="title" :subtitle="subtitle" class="mb-12" />
    <v-container class="mb-12">
      <v-row>
        <v-col
          cols="10"
          sm="8"
          offset="1"
          offset-sm="2"
          md="6"
          offset-md="3"
          lg="5"
          offset-lg="0"
          class="d-flex align-center"
          :class="$vuetify.breakpoint.mdAndDown ? 'mb-12' : ''"
        >
          <v-img
            class="contact-us-img"
            src="~@/assets/images/contact-us-img.svg"
          ></v-img>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          offset="0"
          offset-sm="2"
          md="8"
          offset-md="2"
          lg="6"
          offset-lg="1"
          class="d-flex align-center"
        >
          <HContactForm />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HSectionContact',
  components: {
    HSectionTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionTitle'
      ),
    HContactForm: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HContactForm'
      )
  },
  data: () => ({
    title: 'Want to learn more?',
    subtitle: 'Contact us to embrace the Future of Work.'
  })
}
</script>

<style scoped lang="scss"></style>
