<template>
  <div class="h-section-title">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-column align-center text-center">
            <h1 class="text-h3 section-title grey--text text--darken-2 mb-2">
              {{ title }}
            </h1>
            <h3
              class="text-h5 font-weight-light grey--text text--darken-1 mb-2"
            >
              {{ subtitle }}
            </h3>
            <h1
              class="text-h4 section-highlight primary--text"
              v-if="highlight"
            >
              {{ highlight }}
            </h1>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HSectionTitle',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    highlight: {
      type: String
    },
    static: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHighlight() {
      return !!this.highlight
    }
  }
}
</script>

<style scoped lang="scss">
.h-section-title::v-deep {
  & .section-title {
    font-size: 32px;
    font-weight: 600;
  }

  & .section-highlight {
    font-size: 32px;
    font-weight: bold;
  }
}
</style>
