<template>
  <div class="pa-0 ma-0" id="h-section-product">
    <!-- SECTION TITLE -->
    <HSectionTitle
      :title="title"
      :subtitle="subtitle"
      :highlight="highlight"
      class="mb-12"
    />
    <!-- end SECTION TITLE -->

    <!-- FIRST ROW -->
    <div class="section-block-wave-up">
      <v-container>
        <v-row class="mb-12">
          <v-col
            cols="8"
            offset="2"
            sm="6"
            offset-sm="3"
            md="6"
            offset-md="0"
            lg="5"
            offset-lg="0"
            class="d-flex align-center"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''"
          >
            <div
              :style="
                $vuetify.breakpoint.mdOnly
                  ? { width: '90%' }
                  : { width: '100%' }
              "
            >
              <v-img src="~@/assets/images/product-map-img.svg"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            offset-lg="1"
            class="d-flex align-center"
          >
            <div
              class="d-flex flex-column align-start"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'mx-4'
                  : $vuetify.breakpoint.smOnly
                  ? 'mx-12'
                  : ''
              "
            >
              <div
                class="d-flex"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'
                "
              >
                <v-img
                  src="~@/assets/images/product-map-icon.svg"
                  width="75"
                  :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
                ></v-img>
                <HRowTitle
                  :title="firstRow.title"
                  :subtitle="firstRow.subtitle"
                />
              </div>
              <HSectionalDivider />
              <HRowParagraphs :paragraphs="firstRow.paragraphs" />
              <HActionButton
                :callToAction="firstRow.callToAction"
                small
                text
                hasIcon
                color="primary"
                @setTab="setTab"
                @clickedTab="clickedTab"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- end FIRST ROW -->

    <!-- SECOND ROW -->
    <div class="section-block-full">
      <v-container>
        <v-row
          class="mb-12"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''"
        >
          <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-center">
            <div
              class="d-flex flex-column align-start"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'mx-4'
                  : $vuetify.breakpoint.smOnly
                  ? 'mx-12'
                  : ''
              "
            >
              <div
                class="d-flex"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'
                "
              >
                <v-img
                  src="~@/assets/images/product-connect-icon.svg"
                  width="75"
                  :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
                ></v-img>
                <HRowTitle
                  :title="secondRow.title"
                  :subtitle="secondRow.subtitle"
                />
              </div>
              <HSectionalDivider />
              <HRowParagraphs :paragraphs="secondRow.paragraphs" />
              <HActionButton
                :callToAction="secondRow.callToAction"
                small
                text
                hasIcon
                color="primary"
                @setTab="setTab"
                @clickedTab="clickedTab"
              />
            </div>
          </v-col>
          <v-col
            cols="8"
            offset="2"
            sm="6"
            offset-sm="3"
            md="6"
            offset-md="0"
            lg="5"
            offset-lg="1"
            class="d-flex align-center"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''"
          >
            <div
              :style="
                $vuetify.breakpoint.mdOnly
                  ? { width: '90%' }
                  : { width: '100%' }
              "
            >
              <v-img src="~@/assets/images/product-connect-img.svg"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- end SECOND ROW -->

    <!-- THIRD ROW -->
    <div class="section-block-wave-down">
      <v-container>
        <v-row class="mb-12">
          <v-col
            cols="8"
            offset="2"
            sm="6"
            offset-sm="3"
            md="6"
            offset-md="0"
            lg="5"
            offset-lg="0"
            class="d-flex align-center"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''"
          >
            <div
              :style="
                $vuetify.breakpoint.mdOnly
                  ? { width: '90%' }
                  : { width: '100%' }
              "
            >
              <v-img src="~@/assets/images/product-strengthen-img.svg"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            offset-lg="1"
            class="d-flex align-center"
          >
            <div
              class="d-flex flex-column align-start"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'mx-4'
                  : $vuetify.breakpoint.smOnly
                  ? 'mx-12'
                  : ''
              "
            >
              <div
                class="d-flex"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'
                "
              >
                <v-img
                  src="~@/assets/images/product-strengthen-icon.svg"
                  width="75"
                  :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
                ></v-img>
                <HRowTitle
                  :title="thirdRow.title"
                  :subtitle="thirdRow.subtitle"
                />
              </div>
              <HSectionalDivider />
              <HRowParagraphs :paragraphs="thirdRow.paragraphs" />
              <HActionButton
                :callToAction="thirdRow.callToAction"
                small
                text
                hasIcon
                color="primary"
                @setTab="setTab"
                @clickedTab="clickedTab"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- end THIRD ROW -->
  </div>
</template>

<script>
export default {
  name: 'HSectionProduct',
  components: {
    HSectionTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionTitle'
      ),
    HSectionalDivider: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionalDivider'
      ),
    HRowTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HRowTitle'
      ),
    HRowParagraphs: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HRowParagraphs'
      ),
    HActionButton: () =>
      import(
        /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
      )
  },
  data: () => ({
    title: 'The way you work continues to evolve.',
    subtitle: 'But how you leverage your talent hasn’t.',
    highlight: 'Hyla can help. Here’s how:',
    rowsContent: {
      first: {
        title: 'map people & skills',
        subtitle: 'understand your people',
        paragraphs: [
          {
            highlight: false,
            text: 'Visualize your employees’ unique and continually evolving skill set.'
          },
          {
            highlight: false,
            text: 'Access real-time skill evaluation data to understand current strengths and weaknesses.'
          },
          {
            highlight: false,
            text: 'Seamlessly create customized opportunities to close existing skill gaps.'
          }
        ],
        callToAction: 'Learn more'
      },
      second: {
        title: 'connect to opportunities',
        subtitle: 'continuous upskilling',
        paragraphs: [
          {
            highlight: false,
            text: 'Match your employees’ development areas to bespoke learning opportunities.'
          },
          {
            highlight: false,
            text: 'Easily integrate learning and development offerings into our platform.'
          },
          {
            highlight: false,
            text: 'Foster a culture of lifelong learning, leadership, and continuous self-improvement.'
          }
        ],
        callToAction: 'Learn more'
      },
      third: {
        title: 'strengthen your workforce',
        subtitle: 'introduce superteams',
        paragraphs: [
          {
            highlight: false,
            text: 'Mix and match talent to create rich and diverse superteams.'
          },
          {
            highlight: false,
            text: 'Access info that matters most: skill set proficiency and critical development areas.'
          },
          {
            highlight: false,
            text: 'Foster deep relationships of remote and distributed teams through continuous feedback.'
          }
        ],
        callToAction: 'Learn more'
      }
    }
  }),
  computed: {
    firstRow() {
      return this.rowsContent.first
    },
    secondRow() {
      return this.rowsContent.second
    },
    thirdRow() {
      return this.rowsContent.third
    }
  },
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    }
  }
}
</script>

<style scoped lang="scss">
#h-section-product::v-deep {
  & .section-block-wave-up {
    background-image: url('../assets/images/product-wave-up.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .section-block-wave-down {
    background-image: url('../assets/images/product-wave-down.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .section-block-full {
    background-color: rgba($primary-lighten4-rgb, 0.25);
  }
}
</style>
