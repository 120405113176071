<template>
  <div class="pa-0 ma-0" id="h-section-about">
    <HSectionTitle :title="title" :subtitle="subtitle" class="mb-12" />
    <v-container>
      <v-row>
        <v-col
          cols="8"
          offset="2"
          sm="6"
          offset-sm="3"
          md="6"
          offset-md="0"
          lg="5"
          offset-lg="0"
          class="d-flex align-center"
        >
          <div
            class="masked-image"
            :style="
              $vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' }
            "
          >
            <v-img
              src="~@/assets/images/about-frog.jpg"
              lazy-src="@/assets/lazy/about-frog.jpg"
              class="image-main"
            ></v-img>
            <v-img
              src="~@/assets/images/about-mask.svg"
              class="image-mask"
            ></v-img>

            <div class="dot dot-A levitate"></div>
            <div class="dot dot-B levitate delay-C"></div>
            <div class="dot dot-C levitate delay-B"></div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          offset-lg="1"
          class="d-flex align-center"
        >
          <div
            class="d-flex flex-column align-start"
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : $vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''
            "
          >
            <HRowTitle :title="firstRow.title" :subtitle="firstRow.subtitle" />
            <HSectionalDivider />
            <HRowParagraphs :paragraphs="firstRow.paragraphs" />
          </div>
        </v-col>
      </v-row>

      <div class="mb-12 special-block">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-center">
            <div
              class="d-flex flex-column align-start"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'mx-4'
                  : $vuetify.breakpoint.smOnly
                  ? 'mx-12'
                  : ''
              "
            >
              <HRowTitle
                :title="secondRow.title"
                :subtitle="secondRow.subtitle"
              />
              <HSectionalDivider />
              <HRowParagraphs :paragraphs="secondRow.paragraphs" />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="8"
            offset="2"
            sm="6"
            offset-sm="3"
            md="6"
            offset-md="0"
            lg="5"
            offset-lg="0"
            class="d-flex align-center"
          >
            <div
              class="d-flex align-center justify-center"
              :style="
                $vuetify.breakpoint.mdOnly
                  ? { width: '90%' }
                  : { width: '100%' }
              "
            >
              <div class="left-frog mr-8">
                <HLogoSymbolMark />
              </div>
              <div class="middle-frog">
                <HLogoSymbolMark />
              </div>
              <div class="right-frog ml-2">
                <HLogoSymbolMark />
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            offset-lg="1"
            class="d-flex align-center"
          >
            <div
              class="d-flex flex-column align-start"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'mx-4'
                  : $vuetify.breakpoint.smOnly
                  ? 'mx-12'
                  : ''
              "
            >
              <HRowTitle
                :title="thirdRow.title"
                :subtitle="thirdRow.subtitle"
              />
              <HSectionalDivider />
              <HRowParagraphs :paragraphs="thirdRow.paragraphs" />
            </div>
          </v-col>
        </v-row>

        <div class="bubbles-img">
          <v-img src="~@/assets/images/about-bubble.svg"></v-img>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HSectionAbout',
  components: {
    HSectionTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionTitle'
      ),
    HSectionalDivider: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionalDivider'
      ),
    HRowTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HRowTitle'
      ),
    HRowParagraphs: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HRowParagraphs'
      ),
    HLogoSymbolMark: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/svgs/HLogoSymbolMark'
      )
  },
  data: () => ({
    title: 'The story behind our brand and us.',
    subtitle:
      'A tale of adapting and changing in response to our surroundings.',
    rowsContent: {
      first: {
        title: 'about hyla',
        subtitle: 'our vision',
        paragraphs: [
          {
            highlight: false,
            text: 'The world is in a state of constant change. The way we live, work and interact are evolving at a rapid pace.'
          },
          {
            highlight: false,
            text: 'AI and automation are only further accelerating this, and businesses in all industries are tasked with being incredibly agile in order to maintain a competitive edge in an ever-changing corporate landscape.'
          },
          {
            highlight: true,
            text: 'Yet companies are not equipped to swiftly adapt to such changes.'
          },
          {
            highlight: false,
            text: 'They face an environment where rapidly evolving skills, dynamic personal drivers of their workforce, and a great need for agility exist.'
          },
          {
            highlight: true,
            text: 'So we went out to create a better way to help companies unleash the power of their people and overcome these challenges.'
          }
        ]
      },
      second: {
        title: 'about us',
        subtitle: 'our story and background',
        paragraphs: [
          {
            highlight: false,
            text: 'Our experience spans nearly 40 years helping companies overcome strategic, people and operational challenges.  We’ve consulted and worked with some of the biggest corporations in the world and have helped scale growing companies.'
          },
          {
            highlight: false,
            text: 'Most of all, we’re passionate about unlocking opportunity and growth for everyone and helping companies get better at solving their business problems by maximizing the potential of their people.'
          }
        ]
      },
      third: {
        title: 'What’s in a name?',
        subtitle: 'the meaning behind the brand',
        paragraphs: [
          {
            highlight: false,
            text: 'Hyla is the genus name for a type of tree frog that, like a chameleon, can change color to adapt to its surroundings.'
          },
          {
            highlight: false,
            text: 'We believe that companies of the future must rapidly evolve and adapt to fast-changing environments and economic landscapes. We built Hyla reimagining the future of work.'
          }
        ]
      }
    }
  }),
  computed: {
    firstRow() {
      return this.rowsContent.first
    },
    secondRow() {
      return this.rowsContent.second
    },
    thirdRow() {
      return this.rowsContent.third
    }
  }
}
</script>

<style scoped lang="scss">
#h-section-about::v-deep {
  position: relative;

  & .masked-image {
    position: relative;
    width: 100%;
    & .image-main {
      display: block;
    }
    & .image-mask {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    & .dot {
      position: absolute;
      border-radius: 50%;
      &.dot-A {
        height: 48px;
        width: 48px;
        top: 15%;
        left: 8%;
        background-color: var(--v-primary-lighten3);
        box-shadow: 0 14px 26px -12px rgb($primary-lighten3-rgb, 42%),
          0 4px 23px 0px rgb(0 0 0 / 12%),
          0 8px 10px -5px rgb($primary-lighten3-rgb, 20%) !important;
      }

      &.dot-B {
        height: 64px;
        width: 64px;
        bottom: 22%;
        right: 1%;
        background-color: var(--v-primary-darken1);
        box-shadow: 0 14px 26px -12px rgb($primary-darken1-rgb, 42%),
          0 4px 23px 0px rgb(0 0 0 / 12%),
          0 8px 10px -5px rgb($primary-darken1-rgb, 20%) !important;
      }

      &.dot-C {
        height: 32px;
        width: 32px;
        bottom: 12%;
        left: 15%;
        background-color: var(--v-primary-base);
        box-shadow: 0 14px 26px -12px rgb($primary-base-rgb, 42%),
          0 4px 23px 0px rgb(0 0 0 / 12%),
          0 8px 10px -5px rgb($primary-base-rgb, 20%) !important;
      }

      &.levitate {
        animation-name: levitate;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        &.delay-B {
          animation-delay: 0.5s;
        }

        &.delay-C {
          animation-delay: 0.75s;
        }
      }

      @keyframes levitate {
        0% {
          transform: translate(0, 0);
        }
        50% {
          transform: translate(0, 10px);
        }
        100% {
          transform: translate(0, 0);
        }
      }
    }
  }

  & .special-block {
    position: relative;

    & .left-frog {
      width: 128px;
      & svg {
        transform: rotate(-26deg);
      }
    }

    & .middle-frog {
      width: 160px;
      & svg {
        transform: rotate(18deg);
      }
    }

    & .right-frog {
      width: 96px;
      & svg {
        transform: rotate(32deg);
      }
    }

    & .bubbles-img {
      position: absolute;
      top: 5%;
      right: 10%;
      display: block;
      width: 380px;
    }
  }
}
</style>
