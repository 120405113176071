<template>
  <div class="pa-0 ma-0" id="h-hero">
    <!-- HERO BACKGROUND -->
    <v-img
      class="h-hero-bg"
      src="@/assets/images/hero-frog-bg.jpg"
      lazy-src="@/assets/lazy/hero-frog-bg.jpg"
      :class="$vuetify.breakpoint.smAndDown ? 'smaller' : ''"
      :min-height="heroHeight"
    >
      <div class="fill-height h-hero-gradient"></div>
      <div class="h-hero-wave"></div>
    </v-img>
    <!-- end HERO BACKGROUND -->

    <!-- HERO CONTENT -->
    <div class="h-hero-content">
      <v-container>
        <v-row class="align-center">
          <v-col
            cols="12"
            sm="12"
            md="5"
            lg="5"
            class="d-flex flex-column"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ['order-1', 'align-center']
                : ['order-0', 'align-start']
            "
          >
            <div
              v-for="(text, index) in heroText"
              :key="index"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? ['text-center', 'mb-8']
                  : ['mb-4', 'ml-16']
              "
            >
              <h1
                class="
                  text-h2 text-sm-h1 text-xl-h1 text-lg-h2 text-md-h3
                  font-weight-bold
                  white--text
                  text-capitalize
                "
              >
                {{ text.bold }}
              </h1>
              <h2
                class="
                  text-h5 text-sm-h4 text-xl-h4 text-lg-h5 text-md-h6
                  font-weight-light
                  white--text
                "
              >
                {{ text.light }}
              </h2>
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="10"
            offset-sm="1"
            md="7"
            offset-md="0"
            lg="7"
            offset-lg="0"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown ? ['order-0', 'mt-12'] : 'order-1'
            "
          >
            <!--<div class="img_cont align-center justify-center">-->
            <lottie-animation
              class="align-center justify-center"
              ref="anim"
              :loop="true"
              :autoPlay="false"
              :animationData="
                require('@/assets/animations/hero-animation.json')
              "
            />
            <!--</div>-->
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- end HERO CONTENT -->
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'HHero',
  components: {
    LottieAnimation
  },
  data: () => ({
    heroText: [
      {
        bold: 'innovate',
        light: 'at speed and scale'
      },
      {
        bold: 'optimize',
        light: 'your whole workforce'
      },
      {
        bold: 'engage',
        light: 'the entire employee base'
      },
      {
        bold: 'transform',
        light: 'existing talent pools'
      }
    ]
  }),
  computed: {
    heroHeight() {
      let height
      if (this.$vuetify.breakpoint.xsOnly) {
        height = 1400 //1500
      } else if (this.$vuetify.breakpoint.smOnly) {
        height = 1600
      } else if (this.$vuetify.breakpoint.mdOnly) {
        height = 800
      } else if (this.$vuetify.breakpoint.lgOnly) {
        height = 860
      } else if (this.$vuetify.breakpoint.xlOnly) {
        height = 1200
      }

      return height
    }
  },
  mounted() {
    this.$refs.anim.play()
  }
}
</script>

<style scoped lang="scss">
#h-hero::v-deep {
  position: relative;

  & .h-hero-bg {
    //background: url('../assets/images/hero-frog-bg.jpg') center center/cover
    //  no-repeat;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;

    &.smaller {
      & .v-image__image {
        background-position: 15% !important;
        background-size: cover !important;
      }
    }

    & .h-hero-gradient {
      opacity: 0.6;
      background: linear-gradient(
        -45deg,
        rgba($primary-darken3-rgb, 1),
        rgba($primary-base-rgb, 1),
        rgba($primary-base-rgb, 1),
        rgba($primary-darken3-rgb, 1)
      );
    }

    & .h-hero-wave {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 280px;
      background-image: url('../assets/images/hero-wave.svg');
    }
  }

  & .h-hero-content {
    position: absolute;
    width: 100%;
    top: 120px;

    & .img_cont {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
