<template>
  <div class="pa-0 ma-0" id="h-section-features">
    <!-- SECTION TITLE -->
    <HSectionTitle :title="title" :subtitle="subtitle" class="mb-4" />
    <!-- end SECTION TITLE -->

    <!-- FEATURES -->
    <div class="section-block-full py-12">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="8"
            offset-sm="2"
            md="6"
            offset-md="0"
            lg="4"
            offset-lg="0"
            v-for="(feature, index) in featuresRow"
            :key="index"
          >
            <HFeaturesCard
              :icon="feature.icon"
              :title="feature.title"
              :text="feature.text"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- end FEATURES -->

    <!-- CALL TO ACTION -->
    <v-container>
      <v-row class="mt-6 mb-12">
        <v-col cols="12">
          <div class="d-flex align-center justify-center">
            <HActionButton
              :callToAction="callToAction"
              rounded
              color="primary"
              @setTab="setTab"
              @clickedTab="clickedTab"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- end CALL TO ACTION -->
  </div>
</template>

<script>
export default {
  name: 'HSectionFeatures',
  components: {
    HSectionTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionTitle'
      ),
    HFeaturesCard: () =>
      import(
        /* webpackChunkName: "features" */ '@/components/sections/HFeaturesCard'
      ),
    HActionButton: () =>
      import(
        /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
      )
  },
  data: () => ({
    title: 'Unleash the power of your people.',
    subtitle: 'Supercharge the way you work.',
    featuresRow: [
      {
        icon: 'feature-optimize-icon',
        title: 'Optimize the workforce',
        text: 'Place people and focus upskilling efforts to align with the overall business strategy.'
      },
      {
        icon: 'feature-bias-icon',
        title: 'Bias-free experience',
        text: 'Hyla’s AI-driven and anonymous skill matching removes bias throughout the entire process..'
      },
      {
        icon: 'feature-unlock-icon',
        title: 'Unlock potential',
        text: 'Transform the work environment so that all employees have the best chance of success and can participate to their fullest potential.'
      },
      {
        icon: 'feature-innovate-icon',
        title: 'Innovate faster',
        text: 'Reduce employee turnover, mix and match “superteams” to boost creativity and innovation.'
      },
      {
        icon: 'feature-uncover-icon',
        title: 'Uncover skills',
        text: 'Unlock and understand the skill matrix of the whole company while uncovering strengths and weaknesses.'
      },
      {
        icon: 'feature-transparent-icon',
        title: 'Transparent opportunities',
        text: 'Democratize growth possibilities, removing the need for workers to jump through hoops to get tapped for a project or promotion.'
      }
    ],
    callToAction: 'Get Started'
  }),
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    }
  }
}
</script>

<style scoped lang="scss">
#h-section-features::v-deep {
  & .section-block-full {
    background-color: rgb($primary-lighten4-rgb, 25%);

    & .blur {
      opacity: 0.2 !important;
    }
  }
}
</style>
