<template>
  <v-container
    fluid
    class="ma-0 pa-0"
    @wheel.passive="setWheel()"
    @keyup.up.passive="setWheel()"
    @keyup.down.passive="setWheel()"
  >
    <!-- HERO -->
    <HHero
      ref="heroRef"
      v-intersect.quiet="{
        handler: intersectHero,
        options: { threshold: [0.25, 0.75] }
      }"
    />
    <!-- end HERO -->

    <!-- SECTION PRODUCT -->
    <HSectionProduct
      @setTab="setTab"
      @clickedTab="clickedTab"
      v-intersect.quiet="{
        handler: handleIntersect,
        options: { threshold: [0.25, 0.75] }
      }"
    />
    <!-- end SECTION PRODUCT -->

    <!-- SECTION FEATURES -->
    <HSectionFeatures
      @setTab="setTab"
      @clickedTab="clickedTab"
      v-intersect.quiet="{
        handler: handleIntersect,
        options: { threshold: [0.25, 0.75] }
      }"
    />
    <!-- end SECTION FEATURES -->

    <!-- SECTION WHY -->
    <HSectionWhy
      @setTab="setTab"
      @clickedTab="clickedTab"
      v-intersect.quiet="{
        handler: handleIntersect,
        options: { threshold: [0.25, 0.75] }
      }"
    />
    <!-- end SECTION WHY -->

    <!-- SECTION TESTIMONIALS -->
    <HSectionTestimonials
      @setTab="setTab"
      @clickedTab="clickedTab"
      v-intersect.quiet="{
        handler: intersectTestimonial,
        options: { threshold: [0.25, 0.75] }
      }"
      :active="testimonialActive"
    />
    <!-- end SECTION TESTIMONIALS -->

    <!-- SECTION ABOUT -->
    <HSectionAbout
      v-intersect.quiet="{
        handler: handleIntersect,
        options: { threshold: [0.25, 0.75] }
      }"
    />
    <!-- end SECTION ABOUT -->

    <!-- SECTION CONTACT -->
    <HSectionContact
      v-intersect.quiet="{
        handler: handleIntersect,
        options: { threshold: [0.25, 0.75] }
      }"
    />
    <!-- end SECTION CONTACT -->
  </v-container>
</template>

<script>
import sectionsData from '@/constants/sectionsData'
import HHero from '@/components/HHero'
import HSectionProduct from '@/components/HSectionProduct'
import HSectionFeatures from '@/components/HSectionFeatures'
import HSectionWhy from '@/components/HSectionWhy'
import HSectionTestimonials from '@/components/HSectionTestimonials'
import HSectionAbout from '@/components/HSectionAbout'
import HSectionContact from '@/components/HSectionContact'
export default {
  name: 'Home',
  components: {
    HHero,
    HSectionProduct,
    HSectionFeatures,
    HSectionWhy,
    HSectionTestimonials,
    HSectionAbout,
    HSectionContact
  },
  props: {
    isTabClicked: {
      type: Boolean
    }
  },
  data: () => ({
    testimonialActive: false
  }),
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    },
    setWheel() {
      this.clickedTab(false)
    },
    handleIntersect(entries) {
      if (!this.isTabClicked && entries[0].isIntersecting) {
        let index = sectionsData.findIndex(
          (item) => item.id === `#${entries[0].target.id}`
        )
        this.setTab(index + 1)
      }
    },
    intersectHero(entries) {
      if (!this.isTabClicked && entries[0].isIntersecting) {
        this.setTab(null)
      }
      if (entries[0].isIntersecting) {
        this.$refs.heroRef.$refs.anim.play()
      } else {
        this.$refs.heroRef.$refs.anim.pause()
      }
    },
    intersectTestimonial(entries) {
      this.handleIntersect(entries)
      this.testimonialActive = entries[0].isIntersecting
    }
  },
  mounted() {
    this.$emit('setApp', false)
  }
}
</script>
