<template>
  <div class="pa-0 ma-0" id="h-section-why">
    <HSectionTitle :title="title" :subtitle="subtitle" class="mb-4" />

    <v-container>
      <v-row class="mb-12">
        <v-col
          cols="8"
          offset="2"
          sm="6"
          offset-sm="3"
          md="6"
          offset-md="0"
          lg="5"
          offset-lg="0"
          class="d-flex align-end"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''"
        >
          <div
            :style="
              $vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' }
            "
          >
            <v-img src="~@/assets/images/why-strategic-img.svg"></v-img>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          offset-lg="1"
          class="d-flex align-center"
        >
          <div
            class="d-flex flex-column align-start"
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : $vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''
            "
          >
            <div
              class="d-flex"
              :class="
                $vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'
              "
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndUp"
                src="~@/assets/images/why-strategic-icon.svg"
                width="75"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
              ></v-img>
              <HRowTitleTyped
                :active="active"
                ref="strategicRef"
                :title="firstRow.title"
                :subtitle="firstRow.subtitle"
                :words="firstRow.words"
              />
            </div>

            <HSectionalDivider />
            <HRowParagraphs :paragraphs="firstRow.paragraphs" />
            <HActionButton
              :callToAction="firstRow.callToAction"
              rounded
              color="primary"
              @setTab="setTab"
              @clickedTab="clickedTab"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''"
            />
          </div>
        </v-col>
      </v-row>

      <v-row
        class="mb-12"
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''"
      >
        <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-center">
          <div
            class="d-flex flex-column align-start"
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : $vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''
            "
          >
            <div
              class="d-flex"
              :class="
                $vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'
              "
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndUp"
                src="~@/assets/images/why-tactical-icon.svg"
                width="75"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
              ></v-img>
              <HRowTitleTyped
                :id="secondRow.id"
                :title="secondRow.title"
                :subtitle="secondRow.subtitle"
                :words="secondRow.words"
              />
            </div>
            <HSectionalDivider />
            <HRowParagraphs :paragraphs="secondRow.paragraphs" />
            <HActionButton
              :callToAction="secondRow.callToAction"
              rounded
              color="primary"
              @setTab="setTab"
              @clickedTab="clickedTab"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''"
            />
          </div>
        </v-col>
        <!--<v-col cols="5" offset="1">-->
        <v-col
          cols="8"
          offset="2"
          sm="6"
          offset-sm="3"
          md="6"
          offset-md="0"
          lg="5"
          offset-lg="1"
          class="d-flex align-end"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''"
        >
          <div
            :style="
              $vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' }
            "
          >
            <v-img src="~@/assets/images/why-tactical-img.svg"></v-img>
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-12">
        <v-col
          cols="8"
          offset="2"
          sm="6"
          offset-sm="3"
          md="6"
          offset-md="0"
          lg="5"
          offset-lg="0"
          class="d-flex align-end"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''"
        >
          <div
            :style="
              $vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' }
            "
          >
            <v-img src="~@/assets/images/why-inclusive-img.svg"></v-img>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          offset-lg="1"
          class="d-flex align-center"
        >
          <div
            class="d-flex flex-column align-start"
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : $vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''
            "
          >
            <div
              class="d-flex"
              :class="
                $vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'
              "
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndUp"
                src="~@/assets/images/why-inclusive-icon.svg"
                width="75"
                :class="$vuetify.breakpoint.smAndDown ? 'mr-4' : ''"
              ></v-img>
              <HRowTitleTyped
                :id="thirdRow.id"
                :title="thirdRow.title"
                :subtitle="thirdRow.subtitle"
                :words="thirdRow.words"
              />
            </div>
            <HSectionalDivider />
            <HRowParagraphs :paragraphs="thirdRow.paragraphs" />
            <HActionButton
              :callToAction="thirdRow.callToAction"
              rounded
              color="primary"
              @setTab="setTab"
              @clickedTab="clickedTab"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HSectionWhy',
  components: {
    HSectionTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionTitle'
      ),
    HSectionalDivider: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionalDivider'
      ),
    HRowTitleTyped: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HRowTitleTyped'
      ),
    HRowParagraphs: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HRowParagraphs'
      ),
    HActionButton: () =>
      import(
        /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
      )
  },
  props: {
    active: {
      type: Boolean
    }
  },
  data: () => ({
    title: 'Reimagine work for everyone.',
    subtitle:
      'Support the entire organization, from the C-suite to your newest employees.',
    rowsContent: {
      first: {
        title: 'A <b class="primary--text typing"></b> for leaders',
        subtitle: 'medium & short-term actions',
        words: ['strategic tool', 'secret weapon', 'competitive advantage'],
        paragraphs: [
          {
            highlight: false,
            text: 'Unlock and understand the skill matrix of your whole company while uncovering strengths and weaknesses.'
          },
          {
            highlight: false,
            text: 'Drive digital transformation by allocating people and focusing upskilling efforts to align with your overall business strategy.'
          },
          {
            highlight: false,
            text: 'Speed up project staffing and reduce time to hire while enhancing talent utilization.'
          }
        ],
        callToAction: 'Learn more'
      },
      second: {
        title: 'Engage your <b class="primary--text typing"></b> workforce',
        subtitle: 'long-term vision',
        words: ['remote', 'in-person', 'hybrid'], //distribuite
        paragraphs: [
          {
            highlight: false,
            text: 'Provide seamless, frequent feedback to employees (near and far), offering unique connection points.'
          },
          {
            highlight: false,
            text: 'Rethink and streamline collaboration by creating superteams that boost creativity and innovation.'
          },
          {
            highlight: false,
            text: 'Reduce turnover and build a stand-out culture by matching employees with tailored career paths and learning opportunities.'
          }
        ],
        callToAction: 'Learn more'
      },
      third: {
        title: 'An inclusive platform for <b class="primary--text typing"></b>',
        subtitle: 'diversity and inclusion',
        words: ['everyone', 'managers', 'teammates', 'leaders'],
        paragraphs: [
          {
            highlight: false,
            text: 'Transparently share opportunities and remove the need for employees to jump through hoops to get tapped for a project or promotion.'
          },
          {
            highlight: false,
            text: 'Transform the work environment so that everyone has the best chance of success and can reach their fullest potential.'
          },
          {
            highlight: false,
            text: 'Enable your workforce to experience bias-free mobility using Hyla’s AI-driven and anonymous skill matching.'
          }
        ],
        callToAction: 'Learn more'
      }
    }
  }),
  computed: {
    firstRow() {
      return this.rowsContent.first
    },
    secondRow() {
      return this.rowsContent.second
    },
    thirdRow() {
      return this.rowsContent.third
    }
  },
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    }
  }
}
</script>

<style scoped lang="scss"></style>
