var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0",attrs:{"id":"h-section-product"}},[_c('HSectionTitle',{staticClass:"mb-12",attrs:{"title":_vm.title,"subtitle":_vm.subtitle,"highlight":_vm.highlight}}),_c('div',{staticClass:"section-block-wave-up"},[_c('v-container',[_c('v-row',{staticClass:"mb-12"},[_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-6' : '',attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"0"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdOnly
                ? { width: '90%' }
                : { width: '100%' })},[_c('v-img',{attrs:{"src":require("@/assets/images/product-map-img.svg")}})],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : _vm.$vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'},[_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',attrs:{"src":require("@/assets/images/product-map-icon.svg"),"width":"75"}}),_c('HRowTitle',{attrs:{"title":_vm.firstRow.title,"subtitle":_vm.firstRow.subtitle}})],1),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.firstRow.paragraphs}}),_c('HActionButton',{attrs:{"callToAction":_vm.firstRow.callToAction,"small":"","text":"","hasIcon":"","color":"primary"},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}})],1)])],1)],1)],1),_c('div',{staticClass:"section-block-full"},[_c('v-container',[_c('v-row',{staticClass:"mb-12",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : _vm.$vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'},[_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',attrs:{"src":require("@/assets/images/product-connect-icon.svg"),"width":"75"}}),_c('HRowTitle',{attrs:{"title":_vm.secondRow.title,"subtitle":_vm.secondRow.subtitle}})],1),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.secondRow.paragraphs}}),_c('HActionButton',{attrs:{"callToAction":_vm.secondRow.callToAction,"small":"","text":"","hasIcon":"","color":"primary"},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}})],1)]),_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-6' : '',attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"1"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdOnly
                ? { width: '90%' }
                : { width: '100%' })},[_c('v-img',{attrs:{"src":require("@/assets/images/product-connect-img.svg")}})],1)])],1)],1)],1),_c('div',{staticClass:"section-block-wave-down"},[_c('v-container',[_c('v-row',{staticClass:"mb-12"},[_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-6' : '',attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"0"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdOnly
                ? { width: '90%' }
                : { width: '100%' })},[_c('v-img',{attrs:{"src":require("@/assets/images/product-strengthen-img.svg")}})],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
                ? 'mx-4'
                : _vm.$vuetify.breakpoint.smOnly
                ? 'mx-12'
                : ''},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'},[_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',attrs:{"src":require("@/assets/images/product-strengthen-icon.svg"),"width":"75"}}),_c('HRowTitle',{attrs:{"title":_vm.thirdRow.title,"subtitle":_vm.thirdRow.subtitle}})],1),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.thirdRow.paragraphs}}),_c('HActionButton',{attrs:{"callToAction":_vm.thirdRow.callToAction,"small":"","text":"","hasIcon":"","color":"primary"},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }