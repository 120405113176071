<template>
  <div class="pa-0 ma-0" id="h-section-testimonials">
    <HSectionTitle :title="title" :subtitle="subtitle" class="mb-4" />
    <div class="section-block-full pt-12 pb-8">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="8"
            offset-md="2"
            class="mx-auto overflow-x-visible"
          >
            <v-carousel
              :cycle="active"
              height="320"
              hide-delimiter-background
              :show-arrows="false"
              light
              delimiter-icon="$dot"
              :interval="4000"
            >
              <v-carousel-item
                v-for="(testimonial, index) in testimonials"
                :key="index"
              >
                <div class="d-flex align-center justify-center">
                  <HTestimonialsCard
                    :quote="testimonial.quote"
                    :name="testimonial.name"
                    :industry="testimonial.industry"
                    :icon="testimonial.icon"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row class="mt-6 mb-12">
        <v-col cols="12">
          <div class="d-flex align-center justify-center">
            <HActionButton
              :callToAction="callToAction"
              rounded
              color="primary"
              @setTab="setTab"
              @clickedTab="clickedTab"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HSectionTestimonials',
  components: {
    HSectionTitle: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HSectionTitle'
      ),
    HTestimonialsCard: () =>
      import(
        /* webpackChunkName: "sections" */ '@/components/sections/HTestimonialsCard'
      ),
    HActionButton: () =>
      import(
        /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
      )
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    title: 'Some things people said about us.',
    subtitle: 'Reactions to our concept and words of encouragement.',
    testimonials: [
      {
        name: 'robert c.',
        industry: 'financial services',
        icon: 'icon-financial-services',
        quote:
          '<b>Uncovering specific areas</b> where there is a <b>shortage or lack</b> of skills is a game changer for us.'
      },
      {
        name: 'cameron k.',
        industry: 'tech',
        icon: 'icon-tech',
        quote:
          'Wow!!! This helps <b>organizations better understand</b> the talents of their employees.'
      },
      {
        name: 'keith r.',
        industry: 'information technology & services',
        icon: 'icon-information-services',
        quote:
          'This makes it much easier to <b>create teams that are more likely to be successful</b> on any given project.'
      },
      {
        name: 'rich h.',
        industry: 'venture capital',
        icon: 'icon-venture-capital',
        quote:
          'In addition to mapping skills, the platform allows the organization to <b>see who is a great performer</b>.'
      },
      {
        name: 'francesca l.',
        industry: 'healthcare',
        icon: 'icon-healthcare',
        quote:
          '<b>YES! Finally</b>. It’s been years that we have been looking for something like this.'
      },
      {
        name: 'lauren m.',
        industry: 'consulting',
        icon: 'icon-consulting',
        quote:
          '<b>Obviously</b>, it helps to improve staffing and project outcomes.'
      }
    ],
    callToAction: 'Discover more'
  }),
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    }
  }
}
</script>

<style scoped lang="scss">
.section-block-full::v-deep {
  background-color: rgba(221, 234, 255, 0.25);

  & .blur {
    opacity: 0.2 !important;
  }
}
</style>
