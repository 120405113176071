var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0",attrs:{"id":"h-section-why"}},[_c('HSectionTitle',{staticClass:"mb-4",attrs:{"title":_vm.title,"subtitle":_vm.subtitle}}),_c('v-container',[_c('v-row',{staticClass:"mb-12"},[_c('v-col',{staticClass:"d-flex align-end",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-6' : '',attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"0"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' })},[_c('v-img',{attrs:{"src":require("@/assets/images/why-strategic-img.svg")}})],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
              ? 'mx-4'
              : _vm.$vuetify.breakpoint.smOnly
              ? 'mx-12'
              : ''},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',attrs:{"src":require("@/assets/images/why-strategic-icon.svg"),"width":"75"}}):_vm._e(),_c('HRowTitleTyped',{ref:"strategicRef",attrs:{"active":_vm.active,"title":_vm.firstRow.title,"subtitle":_vm.firstRow.subtitle,"words":_vm.firstRow.words}})],1),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.firstRow.paragraphs}}),_c('HActionButton',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mx-auto' : '',attrs:{"callToAction":_vm.firstRow.callToAction,"rounded":"","color":"primary"},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}})],1)])],1),_c('v-row',{staticClass:"mb-12",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
              ? 'mx-4'
              : _vm.$vuetify.breakpoint.smOnly
              ? 'mx-12'
              : ''},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',attrs:{"src":require("@/assets/images/why-tactical-icon.svg"),"width":"75"}}):_vm._e(),_c('HRowTitleTyped',{attrs:{"id":_vm.secondRow.id,"title":_vm.secondRow.title,"subtitle":_vm.secondRow.subtitle,"words":_vm.secondRow.words}})],1),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.secondRow.paragraphs}}),_c('HActionButton',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mx-auto' : '',attrs:{"callToAction":_vm.secondRow.callToAction,"rounded":"","color":"primary"},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}})],1)]),_c('v-col',{staticClass:"d-flex align-end",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-6' : '',attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"1"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' })},[_c('v-img',{attrs:{"src":require("@/assets/images/why-tactical-img.svg")}})],1)])],1),_c('v-row',{staticClass:"mb-12"},[_c('v-col',{staticClass:"d-flex align-end",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-6' : '',attrs:{"cols":"8","offset":"2","sm":"6","offset-sm":"3","md":"6","offset-md":"0","lg":"5","offset-lg":"0"}},[_c('div',{style:(_vm.$vuetify.breakpoint.mdOnly ? { width: '90%' } : { width: '100%' })},[_c('v-img',{attrs:{"src":require("@/assets/images/why-inclusive-img.svg")}})],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","offset-lg":"1"}},[_c('div',{staticClass:"d-flex flex-column align-start",class:_vm.$vuetify.breakpoint.xsOnly
              ? 'mx-4'
              : _vm.$vuetify.breakpoint.smOnly
              ? 'mx-12'
              : ''},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown ? 'align-center' : 'flex-column'},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-img',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-4' : '',attrs:{"src":require("@/assets/images/why-inclusive-icon.svg"),"width":"75"}}):_vm._e(),_c('HRowTitleTyped',{attrs:{"id":_vm.thirdRow.id,"title":_vm.thirdRow.title,"subtitle":_vm.thirdRow.subtitle,"words":_vm.thirdRow.words}})],1),_c('HSectionalDivider'),_c('HRowParagraphs',{attrs:{"paragraphs":_vm.thirdRow.paragraphs}}),_c('HActionButton',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mx-auto' : '',attrs:{"callToAction":_vm.thirdRow.callToAction,"rounded":"","color":"primary"},on:{"setTab":_vm.setTab,"clickedTab":_vm.clickedTab}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }